@import "theme";
@import "node_modules/angular-notifier/styles";

.spinner {
  margin: 25% auto;
  width: 10vw;
  height: 8vh;
  text-align: center;
  font-size: 0.7vw;
}

.spinner > div {
  background-color: $white;
  height: 100%;
  width: 1.2vw;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.leaflet-tooltip.leafletCustom {
  color: $grey-dark;
}

.map .leaflet-popup-content-wrapper {
  background: $white;
  color: $black;
  font-size: 1vw;
  line-height: 24px;
  opacity: 0.9;
  border-radius: 6px;
  padding: 0;
  overflow: hidden;
}

.map.leaflet-popup-content-wrapper a {
  color: rgba(255, 255, 255, 0.1);
}

.map .leaflet-popup-tip-container {
  width: 30px;
  height: 15px;
}

.map .leaflet-popup-tip {
  background: transparent;
  border: none;
  box-shadow: none;
}

.div-cluster {
  width: 40px !important;
  height: 50px !important;
  position: "relative" !important;
  text-align: "center" !important;
  background-color: "transparent" !important;
  border: "none" !important;
  margin-left: -20px !important;
  margin-top: -50px !important;
}

.leaflet-popup-content {
  text-align: center;
  margin: 0 !important;

  p {
    margin: 0 !important;
  }
}

.popup-cluster {
  width: 100% !important;
}

.hover-device {
  display: flex;
  flex-direction: row;
}

.hover-device td {
  display: flex;
  flex-direction: column;
}

td .value {
  margin: auto;
  font-size: 1vw;
}

.title {
  color: $grey-main;
  font-size: 1vw;
}

td img.value {
  height: 6vh;
  width: auto;
}

.cdk-overlay-pane {
  &.mat-tooltip-panel {
    .mat-tooltip {
      position: relative;
      top: -8px;
      color: $white;
      background-color: $black;
      border: none;
      border-radius: 4px;
      font-size: 14px;
      padding: 3px 8px;
    }
  }
}

svg-icon {
  display: flex;
}

::ng-deep .mat-dialog-container {
  border-radius: 6px;
  padding: 0;
}

.upload-dialog-container .mat-dialog-container {
  width: 1000px;
  height: 691px;
  border-radius: 6px;
  padding: 32px;
}

.information-divider {
  border-top: solid 1px $grey-light;
}

.incident-checkbox-column {
  width: 32px;
  min-width: 32px;

  .mat-checkbox {
    display: flex;
  }
}

.timestamp-column {
  width: 133px;
  min-width: 133px;
}

.device-column {
  width: 130px;
  min-width: 112px;
}

.container-column {
  width: 82px;
  min-width: 82px;
}

.status-column {
  width: 134px;
  min-width: 134px;
}

.radius-column {
  width: 68px;
  min-width: 68px;
}

.position-column {
  width: 14vw;
  max-width: 115px;
  min-width: 55px;
}

.last-authorized-site-column {
  width: 14vw;
  max-width: 115px;
  min-width: 55px;
}

.labels-column {
  width: 105px;
  min-width: 105px;
  gap: 4px;
}

.time-last-site-column {
  width: 186px;
  min-width: 186px;
}

.seq-number-column {
  width: 48px;
  min-width: 48px;
}

.source-column {
  width: 156px;
  min-width: 156px;
}

.battery-column {
  width: 65px;
  min-width: 65px;
}

.maps-column {
  width: 55px;
  min-width: 55px;
}

.state-displayer-column {
  display: flex;
  justify-content: left;
  padding: 8px;
  box-sizing: border-box;
  align-items: center;
}

.large-state-column {
  max-width: 100%;
  width: 100%;
  min-width: 150px;
}

.skeleton-placeholders {
  display: flex;
  flex-direction: column;
}
