//Ce fichier permet de modifier directement les couleurs du site.
//Ces dernières sont placées en variables dans tous les fichiers scss où elles sont utilisées

// Colors used to make elements stand out
$blue-main: #0150e4; // button
$blue-light: #e3eeff; // button
$blue-dark: #1b4dac;

// Colors for warning and other
$red-main: #e40101; // text
$purple-main: #3a2164; // kpi (not defined)
//MPS colors
$red-state: #d0243f; // machine state
$orange-state: #fc9453; // machine state
$green-state: #66b16c; // machine state
$blue-state: #568dc4; // machine state
$grey-state: #818181; // machine state

// Nuance 25%
// White and Black
$white: #fcfcfc; // background
$black: #161616; // navbar, sidebar

// Greys 60%
// Contextual colors
$grey-main: #757575; // button text
$grey-half: #e1e1e1; // everywhere
$grey-lighter-half: #f2f2f2; // toggle hover, disabled, list
$grey-light: #edeef3; // input, hover, border
$grey-dark: #383838; // cancel button
$grey-shadow: #797979; // shadow
$grey-border: #a6a6a6; // border
$grey-border-half: #bbbbbb; // map hover
$grey-scrollbar: #898989;

$site-marker: #9494b8;

$transparent: transparent;
$default-white: #fff;
$default-black: #000;
